// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---pages-404-js": () => import("./../../../pages/404.js" /* webpackChunkName: "component---pages-404-js" */),
  "component---pages-about-js": () => import("./../../../pages/about.js" /* webpackChunkName: "component---pages-about-js" */),
  "component---pages-cgv-js": () => import("./../../../pages/cgv.js" /* webpackChunkName: "component---pages-cgv-js" */),
  "component---pages-home-js": () => import("./../../../pages/home.js" /* webpackChunkName: "component---pages-home-js" */),
  "component---pages-index-js": () => import("./../../../pages/index.js" /* webpackChunkName: "component---pages-index-js" */),
  "component---pages-mentions-legales-js": () => import("./../../../pages/mentions_legales.js" /* webpackChunkName: "component---pages-mentions-legales-js" */),
  "component---pages-not-found-cover-js": () => import("./../../../pages/not-found-cover.js" /* webpackChunkName: "component---pages-not-found-cover-js" */),
  "component---pages-not-found-js": () => import("./../../../pages/not-found.js" /* webpackChunkName: "component---pages-not-found-js" */),
  "component---pages-oeuvres-js": () => import("./../../../pages/oeuvres.js" /* webpackChunkName: "component---pages-oeuvres-js" */),
  "component---src-templates-artiste-artiste-template-js": () => import("./../../../src/templates/artiste/artisteTemplate.js" /* webpackChunkName: "component---src-templates-artiste-artiste-template-js" */),
  "component---src-templates-peinture-peinture-template-js": () => import("./../../../src/templates/peinture/peintureTemplate.js" /* webpackChunkName: "component---src-templates-peinture-peinture-template-js" */)
}

