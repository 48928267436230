import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'leaflet/dist/leaflet.css';
import 'assets/css/index.css';

import 'swiper/css/swiper.min.css';
import 'aos/dist/aos.css';

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />

        <link rel="apple-touch-icon" sizes="180x180" href="/favicon_io/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon_io/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon_io/favicon-16x16.png" />
        <link rel="manifest" href="/favicon_io/site.webmanifest" />

        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="« Else » pour l’ailleurs, et « ink » pour l’encre. Elseink habille vos intérieurs de son encre d’ailleurs. Que ce soit dans un lieu privé ou public, nous apportons cette touche qui évoque des émotions à la fois uniques et universelles."
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.elseink.com/static/elseink.png"
        />
        <meta
          property="og:title"
          content="Elseink | Une touche unique pour votre intérieur."
        />
        <meta
          property="og:description"
          content="« Else » pour l’ailleurs, et « ink » pour l’encre. Elseink habille vos intérieurs de son encre d’ailleurs. Que ce soit dans un lieu privé ou public, nous apportons cette touche qui évoque des émotions à la fois uniques et universelles."
        />
        <meta property="og:url" content="https://thefront.maccarianagency.com/" />
        <link
          href="https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:100,100i,300,300i,400,400i,500,500i,600,600i,700,700i,900,900i&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Inconsolata:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap"
          rel="stylesheet"
        />
        <script
          src="https://kit.fontawesome.com/4c273e6d43.js"
          crossOrigin="anonymous"
        ></script>

      </Helmet>
      {props.children}
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
