module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":true,"disableMinification":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.1.1","publicApiKey":"ZmZiMWJlYmItYzY2MC00ZjBiLTg3NTItOGQ2NDkyNWY2OThjNjM3NTUzMjA2MTg2NzMxMzc1","defaultLang":"fr","currency":"eur","openCartOnAdd":true,"useSideCart":true,"locales":{"fr":{"actions":{"checkout":"Valider le panier"}}},"innerHTML":"\n        <billing section=\"bottom\">\n        <fieldset class=\"snipcart-form__set\">\n        <div class=\"snipcart-form__field\">\n            <snipcart-label class=\"snipcart__font--tiny\" for=\"telephone\">\n                Téléphone\n            </snipcart-label>\n            <snipcart-input name=\"telephone\"></snipcart-input>\n        </div>\n    </fieldset>\n</billing>"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-575ZM6XD75"]},
    }]
